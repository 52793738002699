import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Video from "../../components/video"

const GalleryPage = () => (
  <Layout>
    <SEO title="Gallery" />
    <div className="row">
      <div className="col-sm-2"></div>
      <div className="col-sm-8">
        <Video 
          videoSrcURL="https://www.youtube.com/embed/YfRojokuYG8?autoplay=0&origin=http://agneya.tech"
          videoTitle="Solar Niranjan (Agneya Products) - English"
        /><br />
        <Video 
          videoSrcURL="https://www.youtube.com/embed/MgoAb7Zam4c?autoplay=0&origin=http://agneya.tech"
          videoTitle="सौर निरांजन (अग्नेय Products) - मराठी"
        />
      </div>
      <div className="col-sm-2"></div>
    </div>
  </Layout>
)

export default GalleryPage
