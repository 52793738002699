import React from "react"
import PropTypes from "prop-types"

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video embed-responsive embed-responsive-16by9">
    <iframe
      className="embed-responsive-item"
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen>
    </iframe>
  </div>
)

Video.propTypes = {
  videoSrcURL: PropTypes.string,
  videoTitle: PropTypes.string,
}

Video.defaultProps = {
  videoSrcURL: ``,
  videoTitle: ``,
}

export default Video